import React, { useState, useCallback, useEffect } from "react";
import "./nav.css";
import { Search } from "../Search/Search";
import logo from "../../logo.jpg";
import AboutMe from "../AboutMe/AboutMe";

export const Nav = (props) => {
  const [search, setSearch] = useState(props.search);
  const [isAboutOpen, setIsAboutOpen] = useState(false);

  // Function to close the "About Me" page
  const closeAbout = () => setIsAboutOpen(false);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setIsAboutOpen(false);
      }
    };
    document.addEventListener('keydown', handleEscape);
  
    const handleClickOutside = (event) => {
      if (isAboutOpen && !document.querySelector('.about-me-content').contains(event.target)) {
        setIsAboutOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isAboutOpen]);
  

  // Recieve search data from Search component, init search state
  const recieveSearchText = useCallback((propsChild) => {
    // Update searched text in the state
    setSearch(props);

    // Update the parent's props {handleNavSearch} with the search text
    // (ArtGallery component - Search grandparent component)
    props.handleNavSearch(propsChild);
  }, []);

  return (
    <div id="navContainer" className="nav-container">
      <div id="logoContainer" className="logo-container">
        <img id="logoImage" className="logo-image" alt="Logo of the Anna Ihmova gallery." src={logo} />
      </div>
      <Search search={props.search} handleSearch={recieveSearchText} />
      <div id="iconsContainer" className="icons-container">
        <div id="aboutIconContainer" className="icon-container">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setIsAboutOpen(true);
            }}
          >
            <i id="about" className="fa fa-user icon bar-icon" />
          </a>
        </div>
        <AboutMe isOpen={isAboutOpen} onClose={closeAbout} />
        <div id="contactIconContainer" className="icon-container">
          <a href="mailto:aninkaihm@gmail.com?subject=Interested%20in%20Image">
            <i id="contact" className="fa fa-envelope icon bar-icon" />
          </a>
        </div>
        <div id="instagramIconContainer" className="icon-container">
          <a href="https://www.instagram.com/atelierossss" target="_blank">
            <i id="instagram" className="fab fa-instagram icon bar-icon" />
          </a>
        </div>
        <div id="facebookIconContainer" className="icon-container">
          <a href="https://www.facebook.com/anicka.ihmova" target="_blank">
            <i id="facebook" className="fab fa-facebook-f icon bar-icon" />
          </a>
        </div>
        <div id="tiktokIconContainer" className="icon-container">
          <a href="https://www.tiktok.com/@umelci.alkoholismu" target="_blank">
            <i id="tiktok" className="fab fa-tiktok icon bar-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};
