import React, { useState, useCallback, useEffect } from "react";
import ScrollLock from "react-scrolllock";
import firebase from "../Firebase/firebase";
import { Helmet } from 'react-helmet'; // Import Helmet

import { Nav } from "./Nav/Nav";
import { Gallery } from "./Gallery/Gallery";
import { FloatingArrow } from "./FloatingArrow/FloatingArrow";
import { ZoomCardItem } from "./ZoomCardItem/ZoomCardItem";

import "./art-gallery.css";

export const ArtGallery = (props) => {
  const [lock, setLock] = useState(false);
  const [search, setSearch] = useState("");
  const [card, setCard] = useState([]);
  const [wide, setWide] = useState(false);
  const [imagesData, setImagesData] = useState([]); // State to hold all images data
  
  useEffect(
    () => {
      if (props.windowWidth < 501) {
        console.log("narrow");
        setWide(false);
      } else {
        console.log("wide");
        setWide(true);
      }

      fetchAllImagesData();
    },
    [props.windowWidth] // Occurs when the state within is changing
  );

  const fetchAllImagesData = () => {
    firebase.database().ref("/Cards").once("value", (snapshot) => {
      const data = snapshot.val() ? snapshot.val() : {};
      const imagesArray = Object.keys(data).map(key => data[key]);
      setImagesData(imagesArray);
    });
  };

  const generateGalleryJsonLd = (imagesData) => {
    const galleryJsonLd = {
      "@context": "https://schema.org",
      "@type": "CollectionPage",
      "name": "Art Gallery",
      "description": "A collection of art works of Anna Ihmova",
      "url": "https://example.com/gallery",
      "mainEntity": imagesData.map(imageData => ({
        "@type": "ImageObject",
        "author": "Anna Ihmova", 
        "contentUrl": imageData.imgURL,
        "description": imageData.description,
        "name": imageData.title,
        "uploadDate": imageData.date,
        "keywords": imageData.tags ? imageData.tags.join(", ") : ""
      }))
    };
  
    return JSON.stringify(galleryJsonLd);
  };

  const insertGalleryJsonLdToHead = (galleryJsonLd) => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = galleryJsonLd;
    document.head.appendChild(script);
  };


  // Recieve search data from Nav component, init search state
  const recieveNavSearchText = useCallback(
    (props) => {
      // Update searched text in the state
      setSearch(props);
    },
    [] //search
  );

  const recieveTagSearchText = useCallback(
    (props) => {
      // Update searched text in the state

      setLock(false);

      setSearch(props.toLowerCase());
    },
    [] //search
  );

  const recieveCardDetails = useCallback((propsChild) => {
    let cardId = propsChild.id;
    console.log("from recieveCardDetails", cardId);

    recieveCardFromDB(cardId);
  }, []);

  function recieveCardFromDB(cardId) {
    // DB Request, extract all the data from Firebase
    firebase
      .database()
      .ref("/Cards/" + cardId)
      .once("value", (querySnapShot) => {
        let data = querySnapShot.val() ? querySnapShot.val() : {};
        let card = { ...data };

        // Initialize the state with the data recieved from DB
        setCard(card);
        setLock(true);
      });
  }

  return (
    <div id="ArtGallery" className="wide-art-gallery">
       <Helmet>
        <script type="application/ld+json">
          {generateGalleryJsonLd(imagesData)}
        </script>
      </Helmet>
      <div
        className={
          lock
            ? wide
              ? "art-gallery-background avoid-clicks"
              : "art-gallery-background avoid-clicks no-scroll"
            : ""
        }
      >
        <Nav search={search} handleNavSearch={recieveNavSearchText} />
        <Gallery
          search={search}
          handleGalleryClickedCard={recieveCardDetails}
        />
        {wide && lock && <ScrollLock />}
      </div>

      {card === undefined || card.length === 0 || !lock ? (
        <FloatingArrow />
      ) : (
        <div className={wide ? "zoom-card-wide" : "zoom-card-narrow"}>
          <i
            className="fas fa-times exit-icon"
            onClick={() => setLock(false)}
          />
          <ZoomCardItem card={card} handleTagSearch={recieveTagSearchText} />
        </div>
      )}
    </div>
  );
};
