import React from 'react';
import './aboutme.css'; // Import the CSS
import annaPortrait from "./ananak.jpg"; // Assuming this is a portrait of Anna Ihmova

const AboutMe = ({ isOpen, onClose }) => {
  return (
    <div className={`about-me ${isOpen ? 'open' : ''}`}>
      <a href="#" className="close-icon" onClick={onClose}>
        <i className="fa fa-times"></i>
      </a>
      <div className="about-me-content">
        <h1>About Anna Ihmova</h1>
        <img id="aboutMeImage" className="aboutme-image" alt="Anna Ihmova" src={annaPortrait} />
        <h2>The Tapestry of Emotions through Art</h2>
        <hr />
        <p>
          Nestled in her luminous studio, Anna Ihmova crafts visual stories that beckon the heart and provoke thought. With a palette rich in colors and a diverse selection of mediums—acrylics, pencils, markers, ink, and beads—Anna breathes life into her canvases, turning them into a mosaic of emotions and narratives.
        </p>
        <p>
          From the ethereal whispers of "Between Us" to the contemplative depths of "Thoughts", her artwork spans a wide spectrum of themes and sizes, ranging from intimate A3 paper pieces to grand 120 x 120 cm canvases. Each piece, whether it's the serene "Moon" or the vibrant "Color of the Mind", invites viewers into a world where each stroke and hue has a story to tell.
        </p>
        <p>
          Anna's journey through art is not just about the exploration of materials like beads on "Color of the Mind" or the textured layers of "Act". It's a journey of feeling and experiencing the world in its myriad forms—the silent dialogues of "Rose Hearts", the dual narratives of "Two Worlds", and the harmonious "Peace and Love" all stand as testaments to her belief in the transformative power of art.
        </p>
        <hr />
      </div>
    </div>
  );
};

export default AboutMe;